import React, { useState } from 'react'
import {
  CSelect
} from '@coreui/react'
import { useTranslation } from 'react-i18next';
export const SelectInput = (props) => {
  const [defaultValue] = useState(props.value || 0);
  const { t } = useTranslation();
  const objValue = props.options.find(e => (e.value + '') === (defaultValue + ''))
  return (
    <>
      {props.readOnly ?
        <div className="fake-form-control">{objValue ? objValue.label : 'NA'}</div>
        :
        <CSelect {...props}>
          {props.options.map(option => {
            return <option key={option.value} value={option.value}>{t(option.label)}</option>
          })}
        </CSelect>
      }
    </>
  )
}