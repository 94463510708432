import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { VIcon, Loading } from 'src/_components'
import { uuidv4, formatUrl } from 'src/_helpers'
import { getPreviewWithFile } from './common'
import { UploadService } from 'src/_services'
import { confirmAlert } from 'react-confirm-alert'
import clsx from 'clsx'
import {
  CFormGroup, CLabel, CButton
} from '@coreui/react'
import { useTranslation } from 'react-i18next';
export const UploadBoxInput = props => {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const onDrop = (files) => {
    var acceptedFiles = files.map(file => {
      return Object.assign(file, {
        preview: getPreviewWithFile(file),
        id: uuidv4(),
        msg: 'uploading'
      })
    })
    sendFiles(acceptedFiles)
  };
  const sendFiles = (files) => {
    Promise.all(files.map(file => {
      return sendFile(file)
    })).then(() => {
      onChangeEvent(files)
    })
  }
  const sendFile = (file) => {
    return new UploadService().upload(file).then((fileUploaded) => {
      file.url = fileUploaded
      file.msg = 'done'
    })
      .catch(() => {
        file.msg = 'error'
      })
  }
  const onChangeEvent = (files) => {
    setFiles(files)
    props.onChange(props.name, files.filter(e => e.url).map(e => {
      return e.url
    }))
  }
  const filePreView = (file) => {
    confirmAlert({
      customUI: () => <div className="react-image-viewer-body"><img src={file.preview} alt="img" /></div>,
      closeOnEscape: true,
      closeOnClickOutside: true
    });
  }
  const removeClick = (file) => {
    confirmAlert({
      title: 'Confirm to Remove',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => removeFile(file)
        },
        {
          label: 'No'
        }
      ]
    });
  }
  const removeFile = file => {
    console.log(file)
  }
  return (
    <CFormGroup>
      {props.hidelabel ? <></> : <CLabel htmlFor={props.name} required={props.required}>{t(props.label || props.name)}:</CLabel>}
      <div className="text-box-input">
        <div className={clsx({
          hasFiles: files && files.length,
          vdropzone: true,
          single: !props.multiple
        })}>
          {(files && files.length) ?
            (
              <>
                <div className="vdropzone-files">
                  {
                    files.map(file => (
                      <div className="thumb" key={file.id}>
                        <div className={clsx({
                          uploading: file.msg === 'uploading',
                          error: file.msg === 'error',
                          thumbInner: true
                        })}>
                          {file.msg === 'uploading' && <Loading />}
                          <CButton type="button" className="remove-image-thumb" onClick={() => removeClick(file)}>
                            <VIcon color={'red'} size={'xl'} name='cilXCircle' />
                          </CButton>
                          <img data-tip="hello world" src={formatUrl(file.preview)} alt="img" onClick={() => filePreView(file)} />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            ) : ''
          }
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({
                className: 'vdropzonex'
              })}>
                <input {...getInputProps({ accept: (props.accept || 'image/*'), multiple: props.multiple })} />
                <div className="vdropzone-placeholder">
                  <VIcon size={'xl'} name='cilImagePlus' />
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    </CFormGroup>
  );
};

