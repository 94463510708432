import chroma from 'chroma-js';
export const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (!data.color) return styles
      const color = chroma((data.color || '#666666'));
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? (data.color)
            : isFocused
              ? data.color
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : ('#000'),
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? (data.color)
              : data.color
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      if (!data.color) return styles
      return {
        ...styles,
        backgroundColor: data.color,
      };
    },
    multiValueLabel: (styles, { data }) => {
      if (!data.color) return styles
      return {
        ...styles,
        color: '#000',
      }
    },
    multiValueRemove: (styles, { data }) => {
      if (!data.color) return styles
      return {
        ...styles,
        color: data.color,
        ':hover': {
          backgroundColor: data.color,
          color: 'white',
        },
      }
    },
  };