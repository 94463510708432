import React, { useState } from 'react'
import {
  CButton,
} from "@coreui/react";
import {
  VIcon,
  TransText
} from "src/_components";

import { history } from 'src/_helpers'
import {
  isMobileOnly
} from "react-device-detect";
import {
  SelectMultiBoxInput, TextBoxInput, DateRangeBoxInput
} from "src/_components/inputs/common";

export const PageFilterBox = ({ children, ...props }) => {
  const [filterPar, setValue] = useState({});
  const onChange = (name, value) => {
    filterPar[name] = value;
    setValue(filterPar)
  }
  return (<div className="product-list-header">
    <div className={"d-flex justify-content-between gap5"  + (isMobileOnly && ' flex-moblie')}>
      <div className={'d-flex gap5 ' + (isMobileOnly && ' flex-moblie')}>
        {props.filterForm ? <>
          {
            props.filterForm.map((inp, ind) => {
              return <div key={ind} className={inp.type + '-input-group'}>
                {(inp.type === 'SearchBox') && <TextBoxInput
                  hidelabel
                  name={inp.name}
                  placeholder="Nhập để tìm kiếm"
                  onChange={onChange}
                />}
                {(inp.type === 'DateRangeBox') && <DateRangeBoxInput name={inp.name} onChange={onChange} />}
                {(inp.type === 'MultiSelectBox' && inp.options && inp.options.length > 0) && <SelectMultiBoxInput
                  name={inp.name}
                  hidelabel
                  options={inp.options}
                  onChange={onChange}
                  value={filterPar[inp.name] || [inp.options[0].value]}
                />}
              </div>
            })
          }
        </> : <>
          <TextBoxInput
            name="search"
            hidelabel
            placeholder="Nhập để tìm kiếm"
            onChange={onChange}
          />
        </>}
        <div>
          <CButton
            type="button"
            className={'submit-btn'}
            color="primary"
            onClick={() => props.onFilterBaseSubmit({ ...filterPar, _t: new Date() })}
          >
            <VIcon name="cilSearch" />
          </CButton>
        </div>
      </div>
      {props.btnNewAction && <div>
        <CButton
          color="secondary"
          className="left"
          size="sm"
          onClick={() => history.push(props.btnNewAction)}
        >
          <TransText>Tạo mới</TransText>
        </CButton>
      </div>}
      {children}
    </div>
  </div>)
}