import {
  CPagination
} from "@coreui/react";
export const DataView = ({ children, ...props }) => {
  return (
    <>
      <div>
        {children}
      </div>
      <CPagination
        activePage={props.activePage}
        onActivePageChange={props.onActivePageChange.bind(this)}
        pages={props.pages}
        doubleArrows={true}
        align="start"
      />
    </>
  )
}