import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { colourStyles } from './common'
import {
  CFormGroup, CLabel
} from '@coreui/react'
export const SelectMultiInput = props => {
  const { maxItem } = props;
  const [options, setOptions] = useState(props.options || []);

  const applyCallback = value => {
    setOptions(
      options.map(e => {
        e.isDisabled = maxItem && value.length >= maxItem;
        return e;
      })
    );
    if (props.onChange)
      props.onChange(props.name, (value || []).map(e => e.value));
  };
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const objValue = options.filter(e => props.value.includes(e.value)) || [];
  const { t } = useTranslation();
  return (
    <>
      {props.readonly ? (
        <div className="fake-form-control">
          {objValue.map(e => e.label).join(", ")}
        </div>
      ) : (
        <Select
          styles={colourStyles}
          classNamePrefix="v-select-box is-multi"
          isMulti
          defaultValue={objValue}
          options={options.map(e => {
            e.label = t(e.label)
            return e
          })}
          name={props.name}
          onChange={applyCallback}
        />
      )}
    </>
  );
};
export const SelectMultiBoxInput = props => {
  const { maxItem } = props;
  const [options, setOptions] = useState(props.options || []);

  const applyCallback = value => {
    setOptions(
      options.map(e => {
        e.isDisabled = maxItem && value.length >= maxItem;
        return e;
      })
    );
    if (props.onChange)
      props.onChange(props.name, (value || []).map(e => e.value));
  };
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const objValue = options.filter(e => props.value.includes(e.value)) || [];
  const { t } = useTranslation();
  return (
    <CFormGroup>
      {props.hidelabel ? <></> : <CLabel htmlFor={props.name} required={props.required}>{t(props.label || props.name)}:</CLabel>}
      <div className="select-box-input">
        <Select
          styles={colourStyles}
          classNamePrefix="v-select-box is-multi"
          isMulti
          defaultValue={objValue}
          options={options.map(e => {
            e.label = t(e.label)
            return e
          })}
          name={props.name}
          onChange={applyCallback}
        />
      </div>
    </CFormGroup>
  );
};
