import React from 'react'
import Select from 'react-select'
import { colourStyles } from './common'
import {
  CFormGroup, CLabel
} from '@coreui/react'
import { useTranslation } from 'react-i18next';
export const SelectInput = (props) => {
  const applyCallback = (value) => {
    if (props.onChange)
      props.onChange(props.name, value.value);
  }
  const objValue = props.options?.find(e => e.value === props.value)

  return (
    <>
      {props.readonly ?
        <div className="select-val">{objValue ? objValue.label : ''}</div>
        :
        <Select
          styles={colourStyles}
          classNamePrefix="react-select-v-style"
          value={objValue}
          options={props.options}
          name={props.name}
          onChange={applyCallback}
        />
      }
    </>
  )
}
export const SelectBoxInput = (props) => {
  const { t } = useTranslation();
  const applyCallback = (value) => {
    if (props.onChange)
      props.onChange(props.name, value.value);
  }
  const objValue = props.options?.find(e => e.value === props.value)

  return (
    <CFormGroup>
      <CLabel htmlFor={props.name} required={props.required}>{t(props.label || props.name)}:</CLabel>
      <div className="text-box-input">
        <Select
          styles={colourStyles}
          classNamePrefix="react-select-v-style"
          value={objValue}
          options={props.options.map(e => {
            e.label = t(e.label)
            return e
          })}
          name={props.name}
          onChange={applyCallback}
        />
      </div>
    </CFormGroup>
  )
}