import { serviceCardConstants } from '../_constants';
function add(item) {
  return { type: serviceCardConstants.ADD, item };
}
function edit(item) {
  return { type: serviceCardConstants.EDIT, item };
}
function set(item) {
  return { type: serviceCardConstants.SET, item };
}
function remove() {
  return { type: serviceCardConstants.REMOVE };
}
function setMD(data) {
  return { type: serviceCardConstants.SET_MD, data };
}
export const serviceActions = {
  add,
  edit,
  set,
  remove,
  setMD
};