const imageTypes = [
  'png', 'jpg', 'jpeg', 'gif'
]
const fileTypes = [
  'docx', 'xlsx', 'xls', 'doc', 'json', 'txt', 'csv', 'pdf', 'mp3', 'mp4', 'zip', 'html', 'rar'
]
const getExtFile = (fileName) => {
  var arr = fileName.split('.')
  return arr[arr.length - 1].toLowerCase()
}
export const getPreviewWithFile = (file) => {
  var fileType = getExtFile(file.name)
  if (imageTypes.includes(fileType)) return URL.createObjectURL(file)
  if (fileTypes.includes(fileType)) return `images/files/${fileType}.png`
  return `images/files/file.png`
}
