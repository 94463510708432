import { serviceCardConstants } from '../_constants';
const initialState = null
export function services(state = initialState, action) {
  // var newState = Object.assign([], state)
  console.log(state, action)
  switch (action.type) {
    case serviceCardConstants.SET:
      return action.item;
    case serviceCardConstants.SET_MD:
        return action.item;
    case serviceCardConstants.REMOVE:
        return null;
    case serviceCardConstants.ADD:
        return action.item;
    case serviceCardConstants.CREATED:
      return state;
    case serviceCardConstants.EDIT:
      return state;
    case serviceCardConstants.UPDATED:
      return state;
    default:
      return state
  }
}