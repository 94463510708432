import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import {
  CFormGroup, CLabel
} from '@coreui/react'
import {
  isMobile
} from "react-device-detect";
const format = process.env.REACT_APP_DATETIME_FORMAT
export const DateTimeBoxInput = (props) => {
  const [value, setValue] = useState(props.value ? moment(props.value, format).toDate() : null);
  const { t } = useTranslation();
  const onChange = (value) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(props.name, value ? moment(value).format(format) : '')
    }
  }
  const DateInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className="custom-input" onClick={onClick} ref={ref}>
        {value}
      </div>
    ),
  );
  return (
    <CFormGroup>
      <CLabel htmlFor={props.name} required={props.required}>{t(props.label || props.name)}:</CLabel>
      <div className="text-box-input datetime-box">
        <DatePicker
          showTimeSelect
          className="form-control"
          selected={value}
          onChange={date => onChange(date)}
          timeFormat="HH:mm"
          dateFormat={'HH:mm dd/MM/yyyy'}
          isClearable
          placeholderText={props.placeholder || 'Chọn ngày'}
          maxDate={props.maxDate}
          minDate={props.minDate}
          withPortal={isMobile}
          customInput={<DateInput />}
        />
      </div>
    </CFormGroup>

  );
};